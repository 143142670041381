import React, { useMemo } from 'react'
import { GeoJSON } from 'react-leaflet'
import { TFields, TSelectOption } from 'types/types'
import { PALETTE_NDVI } from 'constants/palette'

import { TooltipCostumer } from '../styles'

type TFieldsPropsObject = {
  field: TFields
  modeNdvi: TSelectOption
  setInfoField: (e: TFields | undefined) => void
  projectSelected: string,
  newWindowClick: boolean
}

const Field = ({ field, modeNdvi, setInfoField, projectSelected, newWindowClick }: TFieldsPropsObject) => {

  const color = useMemo(
    () => {

      if(!field.ndvi && !field.growth_color && modeNdvi.value !== 'hd' && modeNdvi.value !== 'ultra_sense') return '#fff'
      switch (modeNdvi.value) {
        case 'Vegetação':

          const ndviRange = field?.ndvi
            ? parseFloat(field.ndvi?.median.toString()).toFixed(1)
            : 0.0

          return PALETTE_NDVI[ndviRange] || '#FDFCD8'

        case 'hd':

          return 'transparent'

        case 'ultra_sense':

          return 'transparent'

        default:
          return field.growth_color ? field.growth_color : '#fff'
      }}, [field, modeNdvi])

  if (
    !field.shape
        || !field.shape.type
        || (field.shape.type === 'FeatureCollection' && (!field.shape.features || field.shape.features.length === 0))
  ) return null

  return(
    <GeoJSON
      key={`field-polygon-${field.id}`}
      style={{ color: '#fff', fillColor: color, weight: 0.8, fillOpacity: color === '#fff' ? 0.3 : 0.8 }}
      data={field.shape}
      eventHandlers={
        {
          mousemove: () => setInfoField(field),
          mouseout: () => setInfoField(undefined),
          click: () => {
            setInfoField(undefined)
            if(newWindowClick)
              window.open(`/satellite-monitoring/${projectSelected}/field/${field.id}`, '_blank')
          }
        }
      }
    >
      <TooltipCostumer direction='center'>
        <p>{field.name}</p>
      </TooltipCostumer>
    </GeoJSON>
  )
}

export default Field